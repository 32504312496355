<template>
  <div>
    <div class="text-center my-3">
    RESULT
   <!-- <div>
        <div class="result-heading flex p-2 justify-between" v-on:click="toggle()">
			<h4 class="result-name">Emotions Detected</h4>
			<button class="btn btn-transparent">
				<caret-down-icon v-if="!isCollapsed" v-bind:alt="'Open '" v-bind:title="'Open '"></caret-down-icon>
				<caret-up-icon v-else v-bind:alt="'Close '" v-bind:title="'Close '"></caret-up-icon>
			</button>
		</div>
        <div class="relative text-left" v-if="isCollapsed">
            <div class=" my-2">
             <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
            </div>
            </div>
        </div>
  </div> -->

    <result-drop-down-section :title="'Request'"
    v-bind:receivedResult="requestMade"/>

    <result-drop-down-section :title="'Response JSON'"
    v-bind:receivedResult="result"/>
    
    </div>
</div>
</template>

<script>
import ResultDropDownSection from '../ResultDropDownSection.vue';
// import CaretDownIcon from '../../icons/CaretDownIcon';
// import CaretUpIcon from '../../icons/CaretUpIcon.vue';

export default {
  components: { 
    ResultDropDownSection,
    // CaretDownIcon,
    // CaretUpIcon
    },
    data() {
      return {
        isCollapsed: Boolean,
        objects: {}
      }
    },
    methods: {
      toggle() {
          this.isCollapsed = !this.isCollapsed;
        }
    },

    // watch: {
       
    //      receivedObjects: {
    //          immediate:true,
    //          handler(newVal) {
    //         //  console.log(newVal)
    //         var count = {};
    //         this.objects.length = 0;
    //         newVal.forEach(function(para) {
    //             count[para] = (count[para] || 0) + 1;
    //             });
    //             console.log(count);
    //             this.objects = count;
    //             return count;
    //     }}
    // },
    

    props: {
      result:Array,
      isLoading:Boolean,
      requestMade: Array,
      receivedObjects: Array
    }

}
</script>

<style>

</style>